



























































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetSiteParserTemplates,
  dispatchUpdateSiteParserTemplate,
} from '@/store/site-parser-template/actions';
import { readOneSiteParserTemplate } from '@/store/site-parser-template/getters';
import {
  ISiteTemplate,
  ISiteTemplateUpdate,
  siteTemplateInit,
} from '@/interfaces/site-parser-template';

@Component
export default class SiteParserTemplateEdit extends Vue {
  public submitting = false;
  public valid = false;
  public currentTemplate: ISiteTemplate = { ...siteTemplateInit };
  public sitePlugsStr: string = '';

  public async mounted() {
    this.reset();  // чтобы сразу показать в полях значения, которые уже загружены
    await dispatchGetSiteParserTemplates(this.$store);
    this.reset();  // обновляем значения полей, если они изменились
  }

  public reset() {
    if (this.task) {
      this.currentTemplate = {
        ...this.task,
        book: {
          link: {...this.task.book.link},
          title: {...this.task.book.title},
          author: {...this.task.book.author},
        },
        sitePlugs: [...this.task.sitePlugs],
      };
      this.sitePlugsStr = this.serializedSitePlugs;
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const updatedTemplate: ISiteTemplateUpdate = {
        ...this.currentTemplate,
        book: {
          link: {...this.currentTemplate.book.link},
          title: {...this.currentTemplate.book.title},
          author: {...this.currentTemplate.book.author},
        },
        sitePlugs: this.deserializeSitePlugs,
      };
      await dispatchUpdateSiteParserTemplate(
        this.$store,
        { domain: this.task!.domain, template: updatedTemplate },
      );
      this.$router.push('/main/site-parser-template');
    }
  }

  get task() {
    return readOneSiteParserTemplate(this.$store)(this.$router.currentRoute.params.domain);
  }

  get serializedSitePlugs() {
    return this.currentTemplate.sitePlugs.join('\n');
  }

  get deserializeSitePlugs() {
    return this.sitePlugsStr.split('\n');
  }

}
